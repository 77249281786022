// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

.app-social{
    .nav-tabs.menu-list{
        .nav-link{
            padding: .5rem .7rem;
            border: 1px dashed transparent;
            &.active{
                border: 1px dashed var(--primary-color);
            }
        }
    }
}