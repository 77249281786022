// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

.dropify-wrapper{
    background-color: var(--card-color);
    border: 1px solid var(--border-color);
    border-radius: .25rem;
    &:hover{
        background-image: linear-gradient(-45deg, var(--border-color) 25%, transparent 25%, transparent 50%, var(--border-color) 50%, var(--border-color) 75%, transparent 75%, transparent);
    }
    .dropify-message {
        p{
            font-size: 20px;
        }
    }
}