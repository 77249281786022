.landing-page{

    // section: Sliders 
    .section{
        &.sliders{
            .carousel-caption{
                top: 28%;
                right: 10%;
                left: 10%;
                text-shadow: none;
            }
        }
        &.sliders-2{
            background-color: var(--card-color);

            .testimonial-wrap {
                padding-left: 50px;
                @media (max-width: 767px) {
                    padding-left: 0px;
                }
            }
            .testimonial-item {
                border-left: 5px solid var(--primary-color);
                box-sizing: content-box;
                position: relative;
                padding: 30px 30px 30px 40px;
                margin: 30px 15px;
                min-height: 200px;
        
                .testimonial-img {
                    position: absolute;
                    width: 80px;
                    left: -70px;
                    @media (max-width: 767px) {
                        position: static;
                        left: auto;
                    }
                }
                @media (max-width: 767px) {
                    padding: 30px;
                    margin: 15px;
                }
            }
            
            .fa-quote-left,
            .fa-quote-right {
                font-size: 26px;
            }
        
            .swiper-pagination {
                position: relative;
                margin-top: 20px;
        
                .swiper-pagination-bullet {
                    background-color: var(--white-color);
                    border: 1px solid var(--primary-color);
                    width: 12px;
                    height: 12px;
                    opacity: 1;
                }
                .swiper-pagination-bullet-active {
                    background-color: var(--primary-color);
                }
            }
        }
        &.sliders-3{
            background-color: var(--card-color);
        }
        &.sliders-4{
            overflow: hidden;
            width: 100%;
            height: 80vh;
            background-color: rgba(39, 37, 34, 0.8);
            padding: 0;
            
            .carousel-item {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                height: 80vh;

                &::before {
                    content: '';
                    background-color: rgba(12, 11, 10, 0.3);
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                }
            }
            .carousel-container {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 0;
                top: 0;
                left: 0;
                right: 0;
            }
            h2 {
                color: var(--white-color);
                span {
                    background-color: var(--primary-color);
                    padding: 2px 5px;
                }
            }
            .carousel-inner {
                .carousel-item {
                    transition-property: opacity;
                    background-position: center top;
                    opacity: 0;
                }
                .active.carousel-item-start,.active.carousel-item-end {
                    opacity: 0;
                    left: 0;
                    transform: translate3d(0, 0, 0);
                }
                .active,.carousel-item-next.carousel-item-start,
                .carousel-item-prev.carousel-item-end {
                    opacity: 1;
                    transition: 0.5s;
                }
                .carousel-item-next,.carousel-item-prev{
                    left: 0;
                    transform: translate3d(0, 0, 0);
                }
            }
            .carousel-control-next-icon,.carousel-control-prev-icon {
                color: var(--white-color);
                background: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                line-height: 0;
                border-radius: 50px;
                transition: 0.3s;
                width: 54px;
                height: 54px;
                &:hover {
                    color: rgba(255, 255, 255, 0.8);
                }
            }
            .carousel-indicators {
                display: none;
            }
        }
        &.sliders-5{
            height: calc(100vh - 201px);
            padding: 0;
            .hero-container {
                background-attachment: fixed;
                background-position: center center;
                background-size: cover;
            }
        }
    }
}