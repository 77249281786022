// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

.landing-page{

    // section: Sliders 
    .section{
        &.call-action{
            background-image: url(../../assets/img/auth.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            padding: 80px 0;
            z-index: 2;
        
            &:before {
                background: var(--secondary-color);
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: .8;
                z-index: -1;
            }
        }
        &.call-action-6{
            padding: 0;
            .call-img{
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(../../assets/img/gallery/2.jpg);
                background-size: 100%;
                height: 414px;
            }
        }
    }
}