// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

// Select2
.select2-container{	
	&.select2 {
		width: 100% !important;
	}
	.select2-selection{
		background-color: var(--card-color);
		border-color: var(--border-color);
		height: auto;
		position: relative;
		line-height: 1.5;
		padding: .375rem .75rem;

		&:focus-within,
		&:active,
		&:focus,
		&:focus-visible{
			border-color: var(--primary-color) !important;
			outline: none;
			box-shadow: none;
		}

		[role="textbox"]{
			padding-left: 0;
		}

		[role="presentation"]{
			height: auto;
			position: absolute;
			top: 50%;
			right: 5px;
			width: 20px;
		}
	}
	.select2-dropdown{
		box-shadow: 0 8px 17px 2px  rgba($dark, .15);
		border-color: var(--border-color);

		.select2-search__field{
			border-color: var(--border-color) !important;
			height: 30px !important;
			&:focus,
			&:focus-visible{
				border-color: var(--primary-color) !important;
				outline: none;
				box-shadow: none;
			}
		}
	}
	.select2-search{
		line-height: 24px;
		&:focus,
		&:focus-visible{
			border-color: var(--primary-color) !important;
			outline: none;
			box-shadow: none;
		}
	}
	span.select2-selection__rendered{
		line-height: 24px !important;
	}
	ul.select2-selection__rendered{
		line-height: 18px  !important;
	}
	.select2-search__field{
		height: 23px !important;
		margin: 0 !important;
		&:focus,
		&:focus-visible{
			border-color: var(--primary-color) !important;
			outline: none;
			box-shadow: none;
		}
	}
	.select2-selection__choice{
		margin: 2px !important;
	}
	.select2-results__options{
		[aria-selected="true"]{
			background-color: var(--primary-color);
		}
	}
}


.select2-container {
		
	.select2-choice {
		height: auto;
		border: none;
		background: none;
        position: absolute;
		padding: 5px 10px;
		line-height: 27px;
		top: 0;
		left: 0;
		width: 100%;

		>.select2-chosen {
			margin: 0;
		}
		.select2-arrow {
			display: none;
		}
	}
}
.select2-drop-active {
	border-color: var(--border-color);
}
.parsley-required {
	color: red;
}

[data-theme="high-contrast"] {
	.select2-dropdown{
		color: #000000;
	}
	.select2-container{	
		.select2-results__options{
			[aria-selected="true"]{
				background-color: #000000;
			}
		}
	}
}
