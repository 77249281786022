// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme


.tui-calendar{
    .lnb-calendars-item{
        margin-right: 10px;
    }
    .tui-full-calendar-layout{
        background-color: transparent !important;
    }
    input[type=checkbox].tui-full-calendar-checkbox-round+span{
        border-radius: 2px;
    }
}