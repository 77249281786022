// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

.swal2-modal{
    .swal2-actions{
        .swal2-confirm{
            background-color: var(--primary-color);
            &:focus{
                box-shadow: none;
                outline: none;
            }
        }
    }
}