// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

.flatpickr{
    &:disabled,
    &[readonly]{
        background-color: transparent !important;
    }
}

.flatpickr-calendar{
    overflow: hidden;
    .flatpickr-months{
        background-color: var(--primary-color);
        .flatpickr-monthDropdown-months,
        .numInput{
            color: $white;
            option{
                background-color: var(--primary-color);
                font-size: 14px;
            }
        }
        path{
            fill: $white !important;
        }
    }
    .flatpickr-innerContainer{
        .flatpickr-weekdays{
            background-color: var(--primary-color);
            span{
                color: $white;
            }
        }
    }
    .flatpickr-day{
        &.today{
            border-color: var(--primary-color);
            color: var(--primary-color);
            &:hover{
                background-color: var(--primary-color);
                color: $white;
            }
        }
    }
}