// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

.wrapper{
    
    // Login page design version 1
    .page-body{
        &.auth{
            display: flex;
            align-items: center;
            position: relative;
            justify-content: center;
            height: 100vh;
            z-index: 8;
            background: var(--body-color);
            
            &::after{
                position: absolute;
                content: '';
                background-image: url('../img/auth.png');
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: .9;
            }
        }
    }
}